<template>
  <div>
    <main class="content-1400 page-content">
      <div class="pad-4">
        <div class="pad-8">
          <h5 class="color-primary f-normal">
            Neue Bestellung
          </h5>
          <h1 v-if="step == 1">
            Auswahl
          </h1>
          <h1 v-if="step == 2 && page == 'austausch'">
            Begründung des Austausches
          </h1>
          <h1 v-if="step == 2 && page == 'tarife'">
            Auswahl des Produktes
          </h1>
          <h1 v-if="step == 3">
            Kontaktdaten
          </h1>
        </div>
        <section v-if="step == 1">
          <div class="grid-12">
            <div class="col-sp-12 col-tb-4 row-sp-16 pad-8">
              <div class="bgcolor-white br-10 pad-24 t-center">
                <svg width="100px" data-name="Livello 1" id="Livello_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title /><path d="M91,0H37A11,11,0,0,0,26,11V117a11,11,0,0,0,11,11H91a11,11,0,0,0,11-11V11A11,11,0,0,0,91,0ZM32,22.69H96V99.58H32ZM37,6H91a5,5,0,0,1,5,5v5.69H32V11A5,5,0,0,1,37,6ZM91,122H37a5,5,0,0,1-5-5V105.58H96V117A5,5,0,0,1,91,122Z" /><circle cx="64" cy="113.91" r="6" /><path d="M56.13,14.22H71.88a3,3,0,1,0,0-6H56.13a3,3,0,1,0,0,6Z" /></svg>
                <h6 class="mar-top-16">
                  Erstgerät<br>
                  <span class="color-grey f-normal" style="font-size: 0.9em;">inkl. SIM Karte / Vertrag</span>
                </h6>
                <div class="pad-4" />
                <button @click="page = 'tarife'; step = 2" class="button primary">
                  Auswählen
                </button>
              </div>
            </div>
            <div class="col-sp-12 col-tb-4 row-sp-16 pad-8">
              <div class="bgcolor-white br-10 pad-24 t-center">
                <svg class="bi bi-sim" fill="currentColor" viewBox="0 0 16 16" width="100px" xmlns="http://www.w3.org/2000/svg"><path d="M2 1.5A1.5 1.5 0 0 1 3.5 0h7.086a1.5 1.5 0 0 1 1.06.44l1.915 1.914A1.5 1.5 0 0 1 14 3.414V14.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 14.5v-13zM3.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V3.414a.5.5 0 0 0-.146-.353l-1.915-1.915A.5.5 0 0 0 10.586 1H3.5z" /><path d="M5.5 4a.5.5 0 0 0-.5.5V6h2.5V4h-2zm3 0v2H11V4.5a.5.5 0 0 0-.5-.5h-2zM11 7H5v2h6V7zm0 3H8.5v2h2a.5.5 0 0 0 .5-.5V10zm-3.5 2v-2H5v1.5a.5.5 0 0 0 .5.5h2zM4 4.5A1.5 1.5 0 0 1 5.5 3h5A1.5 1.5 0 0 1 12 4.5v7a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 11.5v-7z" /></svg>
                <h6 class="mar-top-16">
                  Simkarte<br><br>
                </h6>
                <div class="pad-4" />
                <button @click="page = 'tarife'; category = 'Simkarte'; step = 2; getTarife()" class="button primary">
                  Auswählen
                </button>
              </div>
            </div>
            <div class="col-sp-12 col-tb-4 row-sp-16 pad-8">
              <div class="bgcolor-white br-10 pad-24 t-center">
                <svg width="100px" data-name="Livello 1" id="Livello_1" viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg"><title /><path d="M91,0H37A11,11,0,0,0,26,11V117a11,11,0,0,0,11,11H91a11,11,0,0,0,11-11V11A11,11,0,0,0,91,0ZM32,22.69H96V99.58H32ZM37,6H91a5,5,0,0,1,5,5v5.69H32V11A5,5,0,0,1,37,6ZM91,122H37a5,5,0,0,1-5-5V105.58H96V117A5,5,0,0,1,91,122Z" /><circle cx="64" cy="113.91" r="6" /><path d="M56.13,14.22H71.88a3,3,0,1,0,0-6H56.13a3,3,0,1,0,0,6Z" /></svg>
                <h6 class="mar-top-16">
                  Austausch-Gerät<br><br>
                </h6>
                <div class="pad-4" />
                <button @click="page = 'austausch'; step = 2" class="button primary">
                  Auswählen
                </button>
              </div>
            </div>
          </div>
        </section>
        <section v-if="step == 2">
          <div v-if="page == 'tarife'">
            <div class="pad-8" style="display: flex; width: calc(100% - 16px)">
              <button :class="[category === 'Smartphone' ? 'primary' : '']" style="width: inherit; border-radius: 0;" class="button" @click="category = 'Smartphone'; getTarife()">
                Smartphones
              </button>
             <button :class="[category === 'Tablets' ? 'primary' : '']" style="width: inherit; border-radius: 0;" class="fluid button" @click="category = 'Tablets'; getTarife()">
               Tablets
              </button>
              <button :class="[category === 'Simkarte' ? 'primary' : '']" style="width: inherit; border-radius: 0;" v-if="reason == ''" class="fluid button" @click="category = 'Simkarte'; getTarife()">
                Simkarte
              </button>
            </div>
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-8" v-for="tarif in tarife" :key="tarif.id">
                <div class="bgcolor-white br-10 pad-24">
                  <div class="pad-8 t-center">
                    <img style="max-height: 150px; width: auto;" :src="config.hauptportal+'assets/images/tarife/'+tarif.tarif_img">
                  </div>
                  <div class="pad-8">
                    <h4>
                      {{ tarif.tarif_device }}
                    </h4>
                    <h5 class="color-grey f-normal mar-bottom-16">
                      {{ tarif.tarif_name }}
                    </h5>
                    <p style="white-space: pre-wrap;">
                      {{ tarif.tarif_description }}
                    </p>
                    <div style="margin: 16px 0; padding: 8px; background-color: #00000010; border-radius: 12px;" v-if="allZubehoer.some(zubehoer => zubehoer.tarifId === tarif.id)">
                      <b style="margin-bottom: 12px;">Folgendes Zubehör steht optional zur Auswahl:</b>
                      <div v-for="zubehoer in allZubehoer" :key="zubehoer.id">
                        <div v-if="zubehoer.tarifId === tarif.id" style="cursor: pointer; vertical-align: middle;">
                          <input type="checkbox" :id="'zubehoer'+zubehoer.id" v-model="tarif.selectedZubehoer[zubehoer.name]" @change="updateTotalPrice(tarif)">
                          <label style="padding-left: 8px;" :for="'zubehoer'+zubehoer.id">{{zubehoer.name}} (+{{(zubehoer.price).toFixed(2)}}€)</label>
                        </div>
                      </div>
                    </div>
                    <p class="t-right" style="font-size: 1.6em; font-weight: bold;">
                      {{ getTarifPriceWithSelectedZubehoer(tarif) }}€<br>
                      <span style="font-size: 0.7em; color: #00000070; font-weight: normal; margin-top: -4px; display: block;">monatlich</span>
                    </p>
                  </div>
                  <div class="pad-8">
                    <div class="pad-4" />
                    <button @click="step = 3; tarifNumber = tarif.tarif_number; getProfiles();" class="primary fluid button">
                      Auswählen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="page == 'austausch'">
            <div class="pad-8">
              <div class="bgcolor-white br-10 pad-24">
                <div class="t-right">
                  <a @click="this.TechnischerSupportModal = true" class="color-primary">
                    Technischer defekt?
                  </a>
                </div>
                <div class="pad-8 t-center">
                  <h3>
                    Wieso möchten Sie das Gerät austauschen?
                  </h3>
                </div>
                <div class="pad-4" />
                <form @submit.prevent="page = 'tarife'">
                  <div class="field mar-bottom-16">
                    <label>Grund:</label>
                    <select name="reason" v-model="reason" required>
                      <option value="" disabled selected style="color: rgba(191,191,191,.87);">
                        Bitte wählen Sie den Grund des Austausches
                      </option>
                      <option value="Gerät zu alt">
                        Gerät zu alt
                      </option>
                      <option value="Verlust / Diebstahl">
                        Verlust / Diebstahl
                      </option>
                      <option value="Sonstiges">
                        Sonstiges
                      </option>
                    </select>
                  </div>
                  <div class="field mar-bottom-16">
                    <label>Beschreibung:</label>
                    <textarea rows="2" name="message" v-model="message" placeholder="Warum benötigst du ein neues Gerät?" required />
                  </div>
                  <button type="submit" class="button primary fluid">
                    Neues Gerät auswählen
                  </button>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section v-if="step == 3">
          <form @submit.prevent="newOrder()">
            <div class="grid-12">
              <div class="col-sp-12 row-sp-16 pad-8">
                <div class="bgcolor-white br-10 pad-24">
                  <div class="pad-8 t-center">
                    <h4>
                      Kontakt Besteller_in
                    </h4>
                  </div>
                  <div class="grid-12">
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label>Name:</label>
                        <input type="text" name="contactname" placeholder="Name" v-model="contactname" required>
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label>E-Mail:</label>
                        <input type="email" name="contactemail" placeholder="E-Mail" v-model="contactemail" required>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 row-sp-16 pad-8">
                <div class="bgcolor-white br-10 pad-24">
                  <div class="field">
                    <label>Anmerkung:</label>
                    <textarea rows="2" name="order_note" placeholder="Anmerkungen" v-model="order_note" />
                  </div>
                </div>
              </div>
              <div class="col-sp-12 row-sp-16 pad-8">
                <div class="bgcolor-white br-10 pad-24">
                  <div class="pad-8 t-center">
                    <h4>
                      Für wen wird bestellt?
                    </h4>
                  </div>
                  <div class="t-right" v-if="reason != ''">
                    <a @click="TechnischerSupportModal = true" class="color-primary">Technischer defekt?</a>
                  </div>
                  <div class="pad-4" />
                  <div class="grid-12">
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label>Name:</label>
                        <input type="text" name="name" placeholder="Name" v-model="name" required>
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label>E-Mail:</label>
                        <input type="email" name="email" placeholder="E-Mail" v-model="email" required>
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label>Firma:</label>
                        <select name="company" v-model="company" required @change="checkLocation()">
                          <option value="boxXpress.de GmbH">boxXpress.de GmbH</option>
                          <option value="EUROGATE Container Terminal Bremerhaven GmbH">EUROGATE Container Terminal Bremerhaven GmbH</option>
                          <option value="EUROGATE Container Terminal Hamburg GmbH">EUROGATE Container Terminal Hamburg GmbH</option>
                          <option value="EUROGATE Container Terminal Wilhelmshaven GmbH & Co. KG">EUROGATE Container Terminal Wilhelmshaven GmbH & Co. KG</option>
                          <option value="EUROGATE GmbH & Co. KGaA, KG">EUROGATE GmbH & Co. KGaA, KG</option>
                          <option value="EUROGATE Intermodal GmbH">EUROGATE Intermodal GmbH</option>
                          <option value="EUROGATE Terminal Services GmbH">EUROGATE Terminal Services GmbH</option>
                          <option value="EUROGATE Technical Services GmbH">EUROGATE Technical Services GmbH</option>
                          <option value="EUROKAI GmbH & Co. KGaA">EUROKAI GmbH & Co. KGaA</option>
                          <option value="EUROKOMBI Terminal GmbH">EUROKOMBI Terminal GmbH</option>
                          <option value="Remain GmbH">Remain GmbH</option>
                          <option value="SWOP Seaworthy Packing GmbH">SWOP Seaworthy Packing GmbH</option>
                          <option value="driveMybox logistics GmbH">driveMybox logistics GmbH</option>
                        </select>
                      </div>

                    </div>
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                      <div class="field">
                        <label>Kostenstelle (ohne führende Null):</label>
                        <input type="text" name="kostenstelle" placeholder="Kostenstelle" v-model="kostenstelle" required>
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-6 row-sp-12 pad-4" v-if="reason != '' || tarifNumber === 9">
                      <div class="field">
                        <label>Rufnummer des <b>auszutauschenden</b> Geräts:</label>
                        <input v-if="category === 'Tablets'" type="text" name="geraetphonenumber" placeholder="Rufnummer" v-model="geraetphonenumber">
                        <input v-else type="text" name="geraetphonenumber" placeholder="Rufnummer" v-model="geraetphonenumber" required>
                      </div>
                    </div>
                    <div v-if="reason != ''" class="col-sp-12 col-tb-6 row-sp-12 pad-4" />
                    <div class="col-sp-12 row-sp-12 pad-4">
                      <div class="pad-4" />
                      <h5>
                        Lieferanschrift
                      </h5>
                    </div>
                    <div class="col-sp-12 row-sp-12 pad-4">
                      <div class="field">
                        <label>Lieferung an Privatadresse gewünscht?</label>
                        <div class="grid-12 mar-top-4">
                          <div class="col-sp-12 col-tb-6 row-sp-12">
                            <div class="field">
                              <input @click="street = ''; hnr = ''; city= ''; plz= ''; country= '';" id="yes" style="display: inline; width: auto;" type="radio" name="privatadress" value="1" tabindex="0" v-model="privatadress" class="hidden">
                              <label class="pad-left-8" for="yes" style="display: inline; width: auto;">Ja</label>
                            </div>
                          </div>
                          <div class="col-sp-12 col-tb-6 row-sp-12">
                            <div class="field">
                              <input id="no" style="display: inline; width: auto;" type="radio" name="privatadress" checked="" value="0" v-model="privatadress" tabindex="0" class="hidden">
                              <label class="pad-left-8" for="no" style="display: inline; width: auto;">Nein</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-sp-12 row-sp-12 pad-4" v-if="privatadress == 0">
                      <div class="field">
                        <label>Standort:</label>
                        <select name="standort" v-model="standort" required @change="setAdress()">
                          <option v-if="company === 'boxXpress.de GmbH'" value="Hamburg" selected>Hamburg</option>
                          <option v-if="company === 'EUROGATE Container Terminal Bremerhaven GmbH'" value="Bremerhaven" selected>Bremerhaven</option>
                          <option v-if="company === 'EUROGATE Container Terminal Hamburg GmbH'" value="Hamburg" selected>Hamburg</option>
                          <option v-if="company === 'EUROGATE Container Terminal Wilhelmshaven GmbH & Co. KG'" value="Wilhelmshaven" selected>Wilhelmshaven</option>
                          <option v-if="company === 'EUROGATE GmbH & Co. KGaA, KG'" value="Bremen">Bremen</option>
                          <option v-if="company === 'EUROGATE GmbH & Co. KGaA, KG'" value="Hamburg">Hamburg</option>
                          <option v-if="company === 'EUROGATE GmbH & Co. KGaA, KG'" value="Bremerhaven">Bremerhaven</option>
                          <option v-if="company === 'EUROGATE Intermodal GmbH'" value="Hamburg" selected>Hamburg</option>
                          <option v-if="company === 'EUROGATE Terminal Services GmbH'" value="Hamburg">Hamburg</option>
                          <option v-if="company === 'EUROGATE Terminal Services GmbH'" value="Bremerhaven">Bremerhaven</option>
                          <option v-if="company === 'EUROGATE Technical Services GmbH'" value="Hamburg">Hamburg</option>
                          <option v-if="company === 'EUROGATE Technical Services GmbH'" value="Bremerhaven">Bremerhaven</option>
                          <option v-if="company === 'EUROGATE Technical Services GmbH'" value="Wilhelmshaven">Wilhelmshaven</option>
                          <option v-if="company === 'EUROKAI GmbH & Co. KGaA'" value="Hamburg" selected>Hamburg</option>
                          <option v-if="company === 'EUROKOMBI Terminal GmbH'" value="Hamburg" selected>Hamburg</option>
                          <option v-if="company === 'Remain GmbH'" value="Hamburg" selected>Hamburg</option>
                          <option v-if="company === 'SWOP Seaworthy Packing GmbH'" value="Hamburg">Hamburg</option>
                          <option v-if="company === 'SWOP Seaworthy Packing GmbH'" value="Bremerhaven">Bremerhaven</option>
                          <option v-if="company === 'driveMybox logistics GmbH'" value="Hamburg">Hamburg</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sp-12 row-sp-12 pad-4" v-if="privatadress == 1">
                      <div class="field">
                        <label>Land</label>
                        <select v-model="country" required>
                          <option value="">
                            Bitte auswählen ...
                          </option>
                          <option value="Deutschland">
                            Deutschland
                          </option>
                          <option value="Österreich">
                            Österreich
                          </option>
                          <option value="Schweiz">
                            Schweiz
                          </option>
                          <option value="" disabled>
                            ------------------
                          </option>
                          <option value="Afghanistan">
                            Afghanistan
                          </option>
                          <option value="Ägypten">
                            Ägypten
                          </option>
                          <option value="Albanien">
                            Albanien
                          </option>
                          <option value="Algerien">
                            Algerien
                          </option>
                          <option value="Andorra">
                            Andorra
                          </option>
                          <option value="Angola">
                            Angola
                          </option>
                          <option value="Anguilla">
                            Anguilla
                          </option>
                          <option value="Antarktis">
                            Antarktis
                          </option>
                          <option value="Antigua und Barbuda">
                            Antigua und Barbuda
                          </option>
                          <option value="Argentinien">
                            Argentinien
                          </option>
                          <option value="Armenien">
                            Armenien
                          </option>
                          <option value="Aruba">
                            Aruba
                          </option>
                          <option value="Äthiopien">
                            Äthiopien
                          </option>
                          <option value="Australien">
                            Australien
                          </option>
                          <option value="Azerbaidschan">
                            Azerbaidschan
                          </option>
                          <option value="Bahamas">
                            Bahamas
                          </option>
                          <option value="Bahrain">
                            Bahrain
                          </option>
                          <option value="Bangladesh">
                            Bangladesh
                          </option>
                          <option value="Barbados">
                            Barbados
                          </option>
                          <option value="Belgien">
                            Belgien
                          </option>
                          <option value="Belize">
                            Belize
                          </option>
                          <option value="Belarus">
                            Belarus
                          </option>
                          <option value="Benin">
                            Benin
                          </option>
                          <option value="Bermuda">
                            Bermuda
                          </option>
                          <option value="Bhutan">
                            Bhutan
                          </option>
                          <option value="Bolivien">
                            Bolivien
                          </option>
                          <option value="Bosnien und Herzegowina">
                            Bosnien und Herzegowina
                          </option>
                          <option value="Botswana">
                            Botswana
                          </option>
                          <option value="Brasilien">
                            Brasilien
                          </option>
                          <option value="Brunei">
                            Brunei
                          </option>
                          <option value="Bulgarien">
                            Bulgarien
                          </option>
                          <option value="Burkina">
                            Burkina
                          </option>
                          <option value="Faso">
                            Faso
                          </option>
                          <option value="Burundi">
                            Burundi
                          </option>
                          <option value="Cape">
                            Cape
                          </option>
                          <option value="Verde">
                            Verde
                          </option>
                          <option value="Cayman Is.">
                            Cayman Is.
                          </option>
                          <option value="Chile">
                            Chile
                          </option>
                          <option value="Volksrepublik China">
                            Volksrepublik China
                          </option>
                          <option value="Christmas Is.">
                            Christmas Is.
                          </option>
                          <option value="Comoros">
                            Comoros
                          </option>
                          <option value="Cook Is.">
                            Cook Is.
                          </option>
                          <option value="Costa Rica">
                            Costa Rica
                          </option>
                          <option value="Cote d Ivoire">
                            Cote d´Ivoire
                          </option>
                          <option value="Cypern">
                            Cypern
                          </option>
                          <option value="D&auml;nemark">
                            Dänemark
                          </option>
                          <option value="Djibouti">
                            Djibouti
                          </option>
                          <option value="Dominica">
                            Dominica
                          </option>
                          <option value="Dominikanische Republik">
                            Dominikanische Republik
                          </option>
                          <option value="Ecuador">
                            Ecuador
                          </option>
                          <option value="El Salvador">
                            El Salvador
                          </option>
                          <option value="Equatorial">
                            Equatorial
                          </option>
                          <option value="Guinea">
                            Guinea
                          </option>
                          <option value="Eritrea">
                            Eritrea
                          </option>
                          <option value="Estland">
                            Estland
                          </option>
                          <option value="Falkland Is.">
                            Falkland Is.
                          </option>
                          <option value="Fiji">
                            Fiji
                          </option>
                          <option value="Finnland">
                            Finnland
                          </option>
                          <option value="Frankreich">
                            Frankreich
                          </option>
                          <option value="Französisch Guiana">
                            Französisch Guiana
                          </option>
                          <option value="Französisch Polynesien">
                            Französisch Polynesien
                          </option>
                          <option value="Gabun">
                            Gabun
                          </option>
                          <option value="Gambia">
                            Gambia
                          </option>
                          <option value="Georgien">
                            Georgien
                          </option>
                          <option value="S. Georgia and the S. Sandwich Is.">
                            S. Georgia and the S. Sandwich Is.
                          </option>
                          <option value="Ghana">
                            Ghana
                          </option>
                          <option value="Grenada">
                            Grenada
                          </option>
                          <option value="Griechenland">
                            Griechenland
                          </option>
                          <option value="Grönland">
                            Grönland
                          </option>
                          <option value="Gro&szlig;britannien">
                            Großbritannien
                          </option>
                          <option value="Guadeloupe">
                            Guadeloupe
                          </option>
                          <option value="Guam">
                            Guam
                          </option>
                          <option value="Guatemala">
                            Guatemala
                          </option>
                          <option value="Guinea-Bissau">
                            Guinea-Bissau
                          </option>
                          <option value="Guyana">
                            Guyana
                          </option>
                          <option value="Haiti">
                            Haiti
                          </option>
                          <option value="Honduras">
                            Honduras
                          </option>
                          <option value="Hong">
                            Hong
                          </option>
                          <option value="Kong">
                            Kong
                          </option>
                          <option value="Indien">
                            Indien
                          </option>
                          <option value="Indonesien">
                            Indonesien
                          </option>
                          <option value="Iran">
                            Iran
                          </option>
                          <option value="Irak">
                            Irak
                          </option>
                          <option value="Irland">
                            Irland
                          </option>
                          <option value="Island">
                            Island
                          </option>
                          <option value="Israel">
                            Israel
                          </option>
                          <option value="Italien">
                            Italien
                          </option>
                          <option value="Jamaica">
                            Jamaica
                          </option>
                          <option value="Japan">
                            Japan
                          </option>
                          <option value="Jordanien">
                            Jordanien
                          </option>
                          <option value="Jugoslawien">
                            Jugoslawien
                          </option>
                          <option value="Kambodscha">
                            Kambodscha
                          </option>
                          <option value="Kamerun">
                            Kamerun
                          </option>
                          <option value="Kanada">
                            Kanada
                          </option>
                          <option value="Kazakhstan">
                            Kazakhstan
                          </option>
                          <option value="Kenia">
                            Kenia
                          </option>
                          <option value="Kiribati">
                            Kiribati
                          </option>
                          <option value="Kitts and Nevis">
                            Kitts and Nevis
                          </option>
                          <option value="Kolumbien">
                            Kolumbien
                          </option>
                          <option value="Kongo">
                            Kongo
                          </option>
                          <option value="Demokratische Republik Kongo">
                            Demokratische Republik Kongo
                          </option>
                          <option value="Nord-Korea">
                            Nord-Korea
                          </option>
                          <option value="Süd-Korea">
                            Süd-Korea
                          </option>
                          <option value="Kroatien">
                            Kroatien
                          </option>
                          <option value="Kuba">
                            Kuba
                          </option>
                          <option value="Kuwait">
                            Kuwait
                          </option>
                          <option value="Kyrgyzstan">
                            Kyrgyzstan
                          </option>
                          <option value="Laos">
                            Laos
                          </option>
                          <option value="Latvia">
                            Latvia
                          </option>
                          <option value="Lesotho">
                            Lesotho
                          </option>
                          <option value="Libanon">
                            Libanon
                          </option>
                          <option value="Liberia">
                            Liberia
                          </option>
                          <option value="Libyen">
                            Libyen
                          </option>
                          <option value="Liechtenstein">
                            Liechtenstein
                          </option>
                          <option value="Lithauen">
                            Lithauen
                          </option>
                          <option value="Luxemburg">
                            Luxemburg
                          </option>
                          <option value="Macao">
                            Macao
                          </option>
                          <option value="Madagaskar">
                            Madagaskar
                          </option>
                          <option value="Makedonien">
                            Makedonien
                          </option>
                          <option value="Malawi">
                            Malawi
                          </option>
                          <option value="Malaysien">
                            Malaysien
                          </option>
                          <option value="Malediven">
                            Malediven
                          </option>
                          <option value="Mali">
                            Mali
                          </option>
                          <option value="Malta">
                            Malta
                          </option>
                          <option value="Northern Marianas Is.">
                            Northern Marianas Is.
                          </option>
                          <option value="Marokko">
                            Marokko
                          </option>
                          <option value="Marshall Is.">
                            Marshall Is.
                          </option>
                          <option value="Martinique">
                            Martinique
                          </option>
                          <option value="Mauritanien">
                            Mauritanien
                          </option>
                          <option value="Mauritius">
                            Mauritius
                          </option>
                          <option value="Mayotte">
                            Mayotte
                          </option>
                          <option value="Mexiko">
                            Mexiko
                          </option>
                          <option value="Mikronesien">
                            Mikronesien
                          </option>
                          <option value="Moldawien">
                            Moldawien
                          </option>
                          <option value="Mongolei">
                            Mongolei
                          </option>
                          <option value="Montserrat">
                            Montserrat
                          </option>
                          <option value="Mozambique">
                            Mozambique
                          </option>
                          <option value="Myanmar">
                            Myanmar
                          </option>
                          <option value="Namibia">
                            Namibia
                          </option>
                          <option value="Nauru">
                            Nauru
                          </option>
                          <option value="Nepal">
                            Nepal
                          </option>
                          <option value="Neukaledonien">
                            Neukaledonien
                          </option>
                          <option value="Neuseeland">
                            Neuseeland
                          </option>
                          <option value="Nicaragua">
                            Nicaragua
                          </option>
                          <option value="Niederlande">
                            Niederlande
                          </option>
                          <option value="Niederländische Antillen">
                            Niederländische Antillen
                          </option>
                          <option value="Niger">
                            Niger
                          </option>
                          <option value="Nigeria">
                            Nigeria
                          </option>
                          <option value="Niue">
                            Niue
                          </option>
                          <option value="Norwegen">
                            Norwegen
                          </option>
                          <option value="Oman">
                            Oman
                          </option>
                          <option value="Pakistan">
                            Pakistan
                          </option>
                          <option value="Palau">
                            Palau
                          </option>
                          <option value="Panama">
                            Panama
                          </option>
                          <option value="Papua-Neu">
                            Papua-Neu
                          </option>
                          <option value="Paraguay">
                            Paraguay
                          </option>
                          <option value="Peru">
                            Peru
                          </option>
                          <option value="Philippinen">
                            Philippinen
                          </option>
                          <option value="Pitcairn">
                            Pitcairn
                          </option>
                          <option value="Is.">
                            Is.
                          </option>
                          <option value="Polen">
                            Polen
                          </option>
                          <option value="Portugal">
                            Portugal
                          </option>
                          <option value="Puerto Rico">
                            Puerto Rico
                          </option>
                          <option value="Qatar">
                            Qatar
                          </option>
                          <option value="Reunion">
                            Reunion
                          </option>
                          <option value="Ruanda">
                            Ruanda
                          </option>
                          <option value="Rumänien">
                            Rumänien
                          </option>
                          <option value="Rußland">
                            Rußland
                          </option>
                          <option value="Saint Lucia">
                            Saint Lucia
                          </option>
                          <option value="Saint Vincent and The Grenadines">
                            Saint Vincent and The Grenadines
                          </option>
                          <option value="Samoa-America">
                            Samoa-America
                          </option>
                          <option value="Samoa-Western">
                            Samoa-Western
                          </option>
                          <option value="San Marino">
                            San Marino
                          </option>
                          <option value="Sao">
                            Sao
                          </option>
                          <option value="Tome and Principe">
                            Tome and Principe
                          </option>
                          <option value="Saudi-Arabien">
                            Saudi-Arabien
                          </option>
                          <option value="Schweden">
                            Schweden
                          </option>
                          <option value="Senegal">
                            Senegal
                          </option>
                          <option value="Seychellen">
                            Seychellen
                          </option>
                          <option value="Sierra Leone">
                            Sierra Leone
                          </option>
                          <option value="Singapur">
                            Singapur
                          </option>
                          <option value="Slowakei">
                            Slowakei
                          </option>
                          <option value="Slowenien">
                            Slowenien
                          </option>
                          <option value="Solomon Is.">
                            Solomon Is.
                          </option>
                          <option value="Somalia">
                            Somalia
                          </option>
                          <option value="Spanien">
                            Spanien
                          </option>
                          <option value="Sri Lanka">
                            Sri Lanka
                          </option>
                          <option value="Südafrika">
                            Südafrika
                          </option>
                          <option value="Sudan">
                            Sudan
                          </option>
                          <option value="Surinam">
                            Surinam
                          </option>
                          <option value="Swaziland">
                            Swaziland
                          </option>
                          <option value="Syrien">
                            Syrien
                          </option>
                          <option value="Taiwan">
                            Taiwan
                          </option>
                          <option value="Tajikistan">
                            Tajikistan
                          </option>
                          <option value="Tansania">
                            Tansania
                          </option>
                          <option value="Thailand">
                            Thailand
                          </option>
                          <option value="Togo">
                            Togo
                          </option>
                          <option value="Tonga">
                            Tonga
                          </option>
                          <option value="Trinidad und Tobago">
                            Trinidad und Tobago
                          </option>
                          <option value="Tschechische Republik">
                            Tschechische Republik
                          </option>
                          <option value="Tunisien">
                            Tunisien
                          </option>
                          <option value="Türkei">
                            Türkei
                          </option>
                          <option value="Turkmenistan">
                            Turkmenistan
                          </option>
                          <option value="Tuvalu">
                            Tuvalu
                          </option>
                          <option value="Uganda">
                            Uganda
                          </option>
                          <option value="Ukraine">
                            Ukraine
                          </option>
                          <option value="Ungarn">
                            Ungarn
                          </option>
                          <option value="Uruguay">
                            Uruguay
                          </option>
                          <option value="Uzbekistan">
                            Uzbekistan
                          </option>
                          <option value="Vanuatu">
                            Vanuatu
                          </option>
                          <option value="Vatikan-Staat">
                            Vatikan-Staat
                          </option>
                          <option value="Venezuela">
                            Venezuela
                          </option>
                          <option value="Vereinigte Arabische Emirate">
                            Vereinigte Arabische Emirate
                          </option>
                          <option value="Vereinigte Staaten von Amerika">
                            Vereinigte Staaten von Amerika
                          </option>
                          <option value="Vietnam">
                            Vietnam
                          </option>
                          <option value="Virgin Is.">
                            Virgin Is.
                          </option>
                          <option value="Western Sahara">
                            Western Sahara
                          </option>
                          <option value="Yemen">
                            Yemen
                          </option>
                          <option value="Zambia">
                            Zambia
                          </option>
                          <option value="Zentralafrikanische Republic">
                            Zentralafrikanische Republic
                          </option>
                          <option value="Zimbabwe">
                            Zimbabwe
                          </option>
                        </select>
                      </div>
                    </div >
                    <div class="col-sp-12 col-tb-10 row-sp-12 pad-4" v-if="privatadress  == 1">
                      <div class="field">
                        <label>Straße</label>
                        <input type="text" name="street" placeholder="Straße" v-model="street" required>
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-2 row-sp-12 pad-4" v-if="privatadress  == 1">
                      <div class="field">
                        <label>Haus Nr.</label>
                        <input type="text" name="hnr" placeholder="Nr" v-model="hnr" required>
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-4 row-sp-12 pad-4" v-if="privatadress  == 1">
                      <div class="field">
                        <label>PLZ</label>
                        <input type="text" name="plz" placeholder="PLZ" v-model="plz" required>
                      </div>
                    </div>
                    <div class="col-sp-12 col-tb-8 row-sp-12 pad-4" v-if="privatadress  == 1">
                      <div class="field">
                        <label>Ort / Stadt</label>
                        <input type="text" name="city" placeholder="Ort / Stadt" v-model="city" required>
                      </div>
                    </div>
                    <div class="col-sp-12 row-sp-12 pad-4">
                      <div class="field">
                        <label>MDM Profil:</label>
                        <p style="font-size: 0.9em; color: #00000080;">
                          Bitte wählen Sie ein Profil aus. Falls Sie es nicht wissen, ist „STANDARD OFFICE“ korrekt.
                        </p>
                        <select name="mdmprofil" v-model="mdmprofil" required>
                          <option value="STANDARD OFFICE" selected>STANDARD OFFICE</option>
                          <option value="KIOSK TPACK">KIOSK TPACK</option>
                          <option value="KIOSK REMAINMYBOX">KIOSK REMAINMYBOX</option>
                          <option value="KIOSK TOPX-MDT">KIOSK TOPX-MDT</option>
                          <option value="KIOSK MOBILE-TERMINAL">KIOSK MOBILE-TERMINAL</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sp-12 row-sp-12 pad-4">
                      <div v-if="reason != ''">
                        <input type="hidden" name="bauertarif" value="">
                      </div>
                      <div v-else>
                        <div class="field" v-if="profiles?.length !== 0">
                          <label>Tarifauswahl:</label>
                          <select name="bauertarif" v-model="bauertarif" required>
                            <option :value="item.name" v-for="item in profiles" :key="item.id">
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="field" v-if="user.role == 1">
                        <label>Notiz für Mendo Consult:</label>
                        <textarea v-model="comment" name="comment" rows="2" placeholder="Notiz für Mendo Consult" />
                      </div>
                    </div>
                  </div>
                  <div class="pad-8" />
                  <button type="submit" class="button fluid primary">
                    Bestellen
                  </button>
                </div>
              </div>
            </div>
          </form>
        </section>
      </div>
    </main>
    <transition name="modal" id="TechnischerSupportModal" style="position: fixed;">
      <div class="vueModal" v-if="TechnischerSupportModal">
        <div class="vueModalOuter">
          <div class="vueModalInner">
            <div>
              <technischerSupport />
            </div>
            <div class="pad-4">
              <button class="button black fluid" @click="TechnischerSupportModal = false">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" id="ErrorModal" style="position: fixed;">
      <div class="vueModal" v-if="errorModal">
        <div class="vueModalOuter">
          <div class="vueModalInner">
            <div class="t-center">
              <h3 class="color-red">
                Es ist ein Fehler aufgetreten:
              </h3>
              <p style="font-size: 0.9em">
                {{ errorMessage }}
              </p>
            </div>
            <div class="pad-4">
              <button class="button black fluid" @click="errorModal = false">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import technischerSupport from "@/components/basic/technischerSupport";
export default {
  name: "NewOrder",
  title: "Bestellung",
  components: {
    technischerSupport: technischerSupport,
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      step: 1,
      page: '',
      category: 'Smartphone',
      reason: '',
      message: '',
      privatadress: 0,
      standort: '',
      company: '',
      TechnischerSupportModal: false,
      tarife: '',
      tarifNumber: '',
      contactname: this.user.firstname+' '+this.user.lastname,
      contactemail: this.user.email,
      errorMessage: '',
      errorModal: false,
      profiles: [],
      mdmprofil: 'STANDARD OFFICE',
      selectedZubehoer: {},
      allZubehoer: [],
      adresses: [
        {
          "company": "boxXpress.de GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Harburger Schloßstr.",
          "hnr": "26",
          "zip": "21079",
          "city": "Hamburg"
        },
        {
          "company": "EUROGATE Container Terminal Bremerhaven GmbH",
          "location": "Bremerhaven",
          "country": "Deutschland",
          "street": "Senator-Borttscheller-Str.",
          "hnr": "1",
          "zip": "27568",
          "city": "Bremerhaven"
        },
        {
          "company": "EUROGATE Container Terminal Hamburg GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "EUROGATE Container Terminal Wilhelmshaven GmbH & Co. KG",
          "location": "Wilhelmshaven",
          "country": "Deutschland",
          "street": "Ozean-Pier",
          "hnr": "1",
          "zip": "26388",
          "city": "Wilhelmshaven"
        },
        {
          "company": "EUROGATE GmbH & Co. KGaA, KG",
          "location": "Bremen",
          "country": "Deutschland",
          "street": "Präsident-Kennedy-Platz",
          "hnr": "1A",
          "zip": "28203",
          "city": "Bremen"
        },
        {
          "company": "EUROGATE GmbH & Co. KGaA, KG",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "EUROGATE GmbH & Co. KGaA, KG",
          "location": "Bremerhaven",
          "country": "Deutschland",
          "street": "Senator-Borttscheller-Str.",
          "hnr": "1",
          "zip": "27568",
          "city": "Bremerhaven"
        },
        {
          "company": "EUROGATE Intermodal GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "EUROGATE Terminal Services GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "EUROGATE Terminal Services GmbH",
          "location": "Bremerhaven",
          "country": "Deutschland",
          "street": "Senator-Borttscheller-Str.",
          "hnr": "1",
          "zip": "27568",
          "city": "Bremerhaven"
        },
        {
          "company": "EUROGATE Technical Services GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "EUROGATE Technical Services GmbH",
          "location": "Bremerhaven",
          "country": "Deutschland",
          "street": "Senator-Borttscheller-Str.",
          "hnr": "1",
          "zip": "27568",
          "city": "Bremerhaven"
        },
        {
          "company": "EUROGATE Technical Services GmbH",
          "location": "Wilhelmshaven",
          "country": "Deutschland",
          "street": "Ozean-Pier",
          "hnr": "1",
          "zip": "26388",
          "city": "Wilhelmshaven"
        },
        {
          "company": "EUROKAI GmbH & Co. KGaA",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "EUROKOMBI Terminal GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Köhlfleetdamm",
          "hnr": "5",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "Remain GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Dradenauer Deichweg",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "SWOP Seaworthy Packing GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        },
        {
          "company": "SWOP Seaworthy Packing GmbH",
          "location": "Bremerhaven",
          "country": "Deutschland",
          "street": "Grauwallring",
          "hnr": "11",
          "zip": "27580",
          "city": "Bremerhaven"
        }
        ,
        {
          "company": "driveMybox logistics GmbH",
          "location": "Hamburg",
          "country": "Deutschland",
          "street": "Kurt-Eckelmann-Str.",
          "hnr": "1",
          "zip": "21129",
          "city": "Hamburg"
        }
      ],
      country: 'Deutschland',
      street: '',
      hnr: '',
      plz: '',
      city: ''

    }
  },
  mounted() {
    this.getTarife()
    this.getZubehoer()
  },
  methods: {
    checkLocation(){
      if(this.company === 'boxXpress.de GmbH'){this.standort = 'Hamburg';this.setAdress()}
      if(this.company === 'EUROGATE Container Terminal Bremerhaven GmbH'){this.standort = 'Bremerhaven';this.setAdress()}
      if(this.company === 'EUROGATE Container Terminal Hamburg GmbH'){this.standort = 'Hamburg';this.setAdress()}
      if(this.company === 'EUROGATE Container Terminal Wilhelmshaven GmbH & Co. KG'){this.standort = 'Wilhelmshaven';this.setAdress()}
      if(this.company === 'EUROGATE Intermodal GmbH'){this.standort = 'Hamburg';this.setAdress()}
      if(this.company === 'EUROKAI GmbH & Co. KGaA'){this.standort = 'Hamburg';this.setAdress()}
      if(this.company === 'EUROKOMBI Terminal GmbH'){this.standort = 'Hamburg';this.setAdress()}
      if(this.company === 'Remain GmbH'){this.standort = 'Hamburg';this.setAdress()}
      if(this.company === 'driveMybox logistics GmbH'){this.standort = 'Hamburg';this.setAdress()}
    },
    setAdress(){
      // Durchlaufe die Adressen
      if(this.privatadress == 0){
        for (let i = 0; i < this.adresses.length; i++) {
          const address = this.adresses[i];
          // Überprüfe, ob company und location übereinstimmen
          if (address.company === this.company && address.location === this.standort) {
            this.country = address.country
            this.street = address.street
            this.hnr = address.hnr
            this.plz = address.zip
            this.city = address.city
          }
        }
      }
      return null;
    },
    getSelectedZubehoerForTarifNumber(number) {
      const tarifWithNumber = this.tarife.find(tarif => tarif.tarif_number === number);
      if (tarifWithNumber) {
        if (Object.keys(tarifWithNumber.selectedZubehoer).length === 0) {
          return "Kein Zubehör";
        } else {
          return Object.keys(tarifWithNumber.selectedZubehoer).filter(item => tarifWithNumber.selectedZubehoer[item]).join(", ");
        }
      } else {
        return "Kein Zubehör";
      }
    },
    updateTotalPrice(tarif) {
      // Überprüfe, ob der Grundpreis (tarif.tarif_price) definiert ist
      if (typeof tarif.originalPrice !== 'undefined') {
        // Berechne den Gesamtpreis des Zubehörs
        let selectedPrices = Object.keys(tarif.selectedZubehoer)
            .filter(zubehoer => tarif.selectedZubehoer[zubehoer])
            .map(zubehoer => this.allZubehoer.find(item => item.name === zubehoer).price);
        let zubehoerTotalPrice = selectedPrices.reduce((acc, curr) => acc + curr, 0);
        // Aktualisiere den Gesamtpreis (tarif.tarif_price) um den Preis des Zubehörs
        tarif.tarif_price = tarif.originalPrice + zubehoerTotalPrice;
      }
    },
    getTarifPriceWithSelectedZubehoer(tarif) {
      // Überprüfe, ob der Grundpreis (tarif.tarif_price) definiert ist
      if (typeof tarif.originalPrice !== 'undefined') {
        // Berechne den Gesamtpreis des Zubehörs
        let selectedPrices = Object.keys(tarif.selectedZubehoer)
            .filter(zubehoer => tarif.selectedZubehoer[zubehoer])
            .map(zubehoer => this.allZubehoer.find(item => item.name === zubehoer).price);
        let zubehoerTotalPrice = selectedPrices.reduce((acc, curr) => acc + curr, 0);
        // Berechne den Gesamtpreis einschließlich des Zubehörs und gib ihn als formatierten String zurück
        return (tarif.originalPrice + zubehoerTotalPrice).toFixed(2);
      }
      // Wenn der Grundpreis nicht definiert ist, gib einen leeren String zurück
      return '';
    },
    getTarife(){
      const _this = this
      const fD = new FormData()
      fD.append('category', this.category)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/getTarife', fD)
          .then((response) => {
            console.log(response.data)
            if(response.status === 250){
              _this.tarife = response.data
              // Iteriere über die Tarife und füge jedem das selectedZubehoer-Objekt hinzu
              _this.tarife.forEach(tarif => {
                tarif.originalPrice = tarif.tarif_price;
                tarif.selectedZubehoer = {};
              });
            }
          })
          .catch(e => {console.error(e)})
    },
    getZubehoer(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/getZubehoer', fD)
          .then((response) => {
            if(response.status === 250){
              _this.allZubehoer = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getProfiles(){
      const _this = this
      const fD = new FormData()
      fD.append('tarifNumber', this.tarifNumber)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/getProfiles', fD)
          .then((response) => {
            if(response.status === 250){
              _this.profiles = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    newOrder(){
      // Überprüfen, ob die erste Ziffer 0 ist
      if (this.kostenstelle.charAt(0) === '0') {
        // Entfernen der ersten Ziffer, falls sie 0 ist
        this.kostenstelle = this.kostenstelle.substring(1);
      }
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('tarif', this.tarifNumber)
      fD.append('reason', this.reason)
      fD.append('message', this.message)
      fD.append('name', this.name)
      fD.append('email', this.email)
      fD.append('contactname', this.contactname)
      fD.append('contactemail', this.contactemail)
      fD.append('company', this.company)
      fD.append('kostenstelle', this.kostenstelle)
      fD.append('geraetphonenumber', this.geraetphonenumber)
      fD.append('country', this.country)
      fD.append('street', this.street)
      fD.append('hnr', this.hnr)
      fD.append('plz', this.plz)
      fD.append('city', this.city)
      fD.append('privatadress', this.privatadress)
      fD.append('bauertarif', this.bauertarif)
      fD.append('comment', this.comment)
      fD.append('order_note', this.order_note)
      fD.append('mdmprofil', this.mdmprofil)
      fD.append('zubehoer', this.getSelectedZubehoerForTarifNumber(this.tarifNumber))
      this.axios.post('bestellung/newOrder', fD)
          .then((response) => {
            if(response.status === 250){
              _this.$router.push('/dashboard?order=success');
            }else{
              _this.errorModal = true
              _this.errorMessage = response.data
            }
          })
          .catch(e => {console.error(e)})
      }
  }
}
</script>

<style scoped>

</style>